@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Miltonian+Tattoo&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-dmsans tracking-tight scroll-smooth box-border ;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a,
  span,
  div,
  li,
  button {
    @apply font-dmsans tracking-tight;
  }

 
}



.no-scrollbar::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.alice-carousel__dots-item.__active {
  background-color: #1A2233; /* Active dot color */
}

/* Additional customization for the dropdown search */
.react-tel-input .country-list {
  @apply max-h-[200px] overflow-y-auto  !important;
}

.react-tel-input .country-list .country {
  @apply text-[14px] px-4 py-2 cursor-pointer hover:bg-gray-200 !important;
}
input[type="time"]::-webkit-calendar-picker-indicator {
 font-size: 20px;
   
  }
  .custom-select-button {
    border-radius: 10px !important;
    height: 60px;
    border: '#D9D9D9';
  }
  